.home-container {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.94);
	margin-top: 44px;
	.square {
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		min-height: 90vh;
		overflow: hidden;
		.image {
			width: 40%;
			min-width: 370px;
		}
		.text {
			font-size: 30px;
			width: 50%;
			color: rgb(51, 197, 255);
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			align-content: center;
			flex-wrap: nowrap;
			h1 {
				word-wrap: normal;
			}
			svg {
				fill: rgba(51, 197, 255);
				width: 100%;
			}
			.buttons {
				width: 100%;
				height: auto;
				display: flex;
				justify-content: center;
				align-items: center;
				button {
					height: 44px;
					margin: 20px;
					font-size: 19px;
					font-weight: 600;
					background-color: rgb(51, 197, 255);
					color: #ffffff;
					border: none;
					border-radius: 22px;
					width: 80%;
				}
				button:hover {
					cursor: pointer;
					opacity: 0.8;
				}
			}
		}
	}
	.glass-1 {
		border-radius: 40px;
		overflow: hidden;
		.square {
			background-color: #fbfbfd;
			filter: blur(3px);
		}
		width: 85%;
		position: absolute;
		box-shadow: 0px 18px 63px -3px rgba(67, 198, 233, 255);
		-webkit-box-shadow: 0px 18px 63px -3px rgba(67, 198, 233, 255);
		-moz-box-shadow: 0px 18px 63px -3px rgba(67, 198, 233, 255);
	}
	.glass-2 {
		box-shadow: 0px 18px 63px -3px rgba(67, 198, 233, 255);
		-webkit-box-shadow: 0px 18px 63px -3px rgba(67, 198, 233, 255);
		-moz-box-shadow: 0px 18px 63px -3px rgba(67, 198, 233, 255);
		overflow: hidden;
		border-radius: 40px;
		width: 85%;
		position: absolute;
		.square {
			background-color: #fbfbfd;
		}
	}
}

// background-color: rgba(107, 195, 230) !important;

//termina theme de bswitch

.info_cards {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	align-content: space-around;
	justify-content: space-around;
	color: #000000;
	margin-top: 10vh;
	.info_card {
		box-shadow: 0px 18px 63px -3px rgba(67, 198, 233, 255);
		-webkit-box-shadow: 0px 18px 63px -3px rgba(67, 198, 233, 255);
		-moz-box-shadow: 0px 18px 63px -3px rgba(67, 198, 233, 255);
		margin: 30px 20px;
		text-align: justify;
		height: 570px;
		border: none;
		border-radius: 30px;
		width: 400px;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		align-items: center;
		.info {
			background-color: #ffffff;
			color: #000000;
			height: auto;
			width: 100%;
			padding: 0 20px 20px 20px;
			position: relative;
			border-radius: 30px;
			z-index: 4;
			margin-top: 530px;

			transition: all 0.5s;
			text-align: center;

			p {
				text-align: justify;
			}
		}
		.card_background_image {
			border-radius: 30px;
			position: absolute;
			z-index: 3;
			margin-top: 100px;
			width: 35vh;
			height: auto;
			object-fit: cover;
			transition: 0.5s all;
		}
		&:hover {
			.card_background_image {
				opacity: 0.5;
				transition: all 0.5s;
			}

			.info {
				margin-top: 40%;
			}
		}
	}
}

@media (max-width: 600px) {
	.soluciones .card {
		width: 100% !important;
	}
}
@media (max-width: 870px) {
	.soluciones .text {
		width: 100% !important;
	}
	.container_img_soluciones {
		width: 100% !important;
	}
}
.soluciones {
	padding-top: 44px;
	text-align: center;
	min-height: 100vh;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.94);
	.cards {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		flex-wrap: nowrap;
		.reversed {
			flex-wrap: wrap-reverse !important;
		}
		.card {
			border-radius: 40px;
			background-color: #fbfbfd;
			padding: 40px;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			margin: 50px 0;
			width: 90%;
			box-shadow: 1px 11px 30px -3px rgba(0, 0, 0, 0.58);
			-webkit-box-shadow: 1px 11px 30px -3px rgba(0, 0, 0, 0.58);
			-moz-box-shadow: 1px 11px 30px -3px rgba(0, 0, 0, 0.58);
			.text {
				width: 50%;
				padding: 20px;
				text-align: justify;
				min-width: 300px;
			}
			.container_img_soluciones {
				padding: 20px;
				object-fit: cover;
				overflow: hidden;
				width: 50%;
				min-width: 300px;
				.imagen_card_soluciones {
					width: 100%;
				}
			}
		}
	}
}
//dashboard
// @media (max-width: 800px) {
// .background-bswitch {
// 	background-image: url("../../assets/images//liquidbs-mobile.png") !important;
// }
// }
// .background-bswitch {
// 	background-image: url("../../assets/images//liquidbs.png");
// 	background-size: cover;
// 	background-position: right;
// 	height: 100%;
// 	position: fixed;
// 	width: 100%;
// 	z-index: -10;
// 	background-repeat: no-repeat;
// }
.Dashboard {
	background-color: #ffffff;
	padding-top: 45px;
	padding-bottom: 100px;
	.p-tabview-nav {
		z-index: 4;
		background-color: rgba(0, 0, 0, 0);
		display: flex;
		justify-content: center;
		border-width: 0px;
	}

	.p-tabview-nav-link {
		padding: 15px !important;
		color: #000000 !important;
		background-color: rgba(255, 255, 255, 0) !important;
		border: none !important;
	}

	.p-tabview-nav-link:focus {
		box-shadow: none !important;
	}
	.p-tabview-ink-bar {
		background-color: #000000 !important;
	}

	.p-tabview-panels {
		height: 100%;
		min-height: 100vh;
		// background-color: rgba(255, 255, 255, 0.94);
		background-color: transparent;
	}

	.newdevicecard {
		color: #ffffff;
		background-color: rgba(0, 0, 0, 0.601);
		border: solid #ffffff 1px;

		input,
		button {
			color: #ffffff;
			border: solid #ffffff 1px;
		}

		.newdevice_icon {
			color: #ffffff;
		}
	}

	.devicecard {
		border: 1px solid #000000;
	}
}

.newdevicecard {
	width: 250px;
	border-radius: 16px;
	padding: 0 10px 0 10px;
	display: flex;
	min-height: 300px;
	flex-direction: column;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	.formulario_newdevice {
		display: flex;
		flex-direction: row;
		justify-content: center;
		flex-wrap: wrap;
		align-items: center;
		.preview_image_upload {
			max-width: 200px;
			max-height: 200px;
			border-radius: 16px;
		}

		.preview_image {
			width: 100%;
			display: flex;
			flex-wrap: nowrap;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		input {
			color: #ffffff;
			height: 30px;
			border-radius: 5px;
			margin: 3px;
			background-color: transparent;
			width: 100%;
		}
		input::placeholder {
			color: #ffffff;
		}
	}

	.image_button {
		margin: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 6px;
		border: solid 1px;

		background-color: transparent;
		width: 100%;
		height: 35px;
	}

	.new_device_button {
		margin: 5px 5px 10px 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 6px;
		background-color: transparent;
		width: 100%;
		height: 35px;
	}
}

.Cards {
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	display: grid;
	gap: 0px;
	justify-items: center;
}

@media (min-width: 600px) {
	.Cards {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (min-width: 800px) {
	.Cards {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media (min-width: 1100px) {
	.Cards {
		grid-template-columns: repeat(4, 1fr);
	}
}

@media (min-width: 1500px) {
	.Cards {
		grid-template-columns: repeat(5, 1fr);
	}
}

.device {
	display: flex;
	justify-content: center;
	align-items: center;

	a {
		text-decoration: none;
		color: #000000;
		transition: all 0.2s;
		.devicecard {
			position: relative;
			width: 250px;
			border-radius: 16px;
			padding: 20px 20px 0 20px;
			height: 300px;
			margin: 10px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			overflow: hidden;

			.use_status {
				width: 100%;
				display: flex;
				justify-content: flex-end;

				.circle {
					height: 15px;
					border-radius: 100%;
					width: 15px;
					border: solid 0.5px #000000;
				}
			}

			.use_status.false .circle {
				background-color: #00ee00;
			}

			.use_status.true .circle {
				background-color: #ff0000;
			}

			.image_container {
				height: 190px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.device_img {
					border-radius: 16px;
					width: 90%;
					height: auto;
				}
			}

			.info_container {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				background-color: #ffffff;
				width: 249px;
				height: 50px;
				border-radius: 0 0 16px 16px;
			}
		}
		&:hover {
			scale: 1.05;
			.info_container {
				color: #fbfbfd;
				background-color: #00000069 !important;
			}
		}
	}
}

//Device page
.devicepage {
	@media (min-width: 600px) {
		.image_container {
			height: 30vh;

			.device_image {
				width: min-content;
			}
		}
	}

	@media (min-width: 600px) {
		.actions {
			width: 100%;
		}
	}

	width: 100%;
	min-height: 100vh;
	height: 100%;

	.device_specs {
		padding: 20px;
		min-height: 60vh;
		height: 100%;
		display: flex;
		flex-wrap: nowrap;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
	}

	.device_text {
		background-color: #00000088;
		border: 1px solid #ffffff;
		color: #ffffff;

		border-radius: 16px;
		width: 40%;
		min-width: 360px;
		margin: 20px;
		padding: 10px;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: nowrap;
		text-align: center;

		input {
			margin-bottom: 10px;
			border-radius: 7px;
			border: solid 1px;
			background-color: transparent;
		}

		.device_tittle_input {
			text-align: center;
			height: 50px;
			font-size: 50px;
			min-width: 340px;
			width: 90%;
			color: #d6433e;
		}

		.device_desc_input {
			text-align: center;
			height: 35px;
			font-size: 18px;
			min-width: 340px;
			width: 90%;
			color: #d6433e;
		}

		.device_category_input {
			text-align: center;
			height: 35px;
			font-size: 18px;
			min-width: 340px;
			width: 90%;
			color: #d6433e;
		}

		.device_color_input {
			text-align: center;
			height: 35px;
			font-size: 18px;
			min-width: 340px;
			width: 90%;
			color: #d6433e;
			padding: 0px;
			background-color: transparent;
		}

		hr {
			width: 50%;
		}

		h1 {
			font-size: 50px;
		}

		h2 {
			margin-bottom: 10px;
		}
	}

	.containers {
		width: 100%;
		height: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.complete-actions {
		.actions-container {
			border-radius: 16px;
			display: flex;
			height: auto;
			margin: 20px;
			padding: 10px;
			min-width: 360px;
			width: 40%;
			flex-direction: column;
			justify-content: space-evenly;
			background-color: #00000088;

			.button-group {
				width: 35%;
				justify-content: flex-end;
				display: flex;

				.delete_button {
					margin: 5px;
					padding: 0;
					width: 40px;
					height: 40px;
					border-radius: 7px;
					border-style: solid;
					color: #ffffff;
					background-color: #d6433e;
					border: solid 0px #ffffff;
				}

				.edit_button {
					margin: 5px;
					padding: 0;
					width: 40px;
					height: 40px;
					border-radius: 7px;
					border-style: solid;
					color: #ffffff;
					background-color: #003399;
					border: solid 0px #ffffff;
				}

				.check_button {
					margin: 5px;
					padding: 0;
					width: 40px;
					height: 40px;
					border-radius: 7px;
					border-style: solid;
					color: #ffffff;
					background-color: #00c200;
					border: solid 0px #ffffff;
				}
			}

			border: 1px solid #ffffff;
			.actions-header {
				color: #ffffff;
				display: flex;
				justify-content: space-between;
				width: 100%;
			}

			.actions {
				display: flex;
				color: #ffffff;
				justify-content: space-between;
				input {
					&::-webkit-calendar-picker-indicator {
						background-color: transparent;
						color-scheme: dark;
					}

					color: #ffffff;
				}
				form {
					h3,
					.button {
						margin-top: 15px;
					}

					input {
						width: 180px;
						height: 35px;
						border-radius: 7px;
						border-style: solid;
						background-color: transparent;
					}

					.button {
						width: 180px;
						height: 35px;
						border-radius: 7px;
						background-color: transparent;
						border: solid 1px;
						border-color: #ffffff;
						color: #ffffff;
					}
				}

				.action_buttons {
					border-color: #ffffff;
					color: #ffffff;
					margin-top: 15px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: space-around;
					width: 50%;

					.action_button {
						background-color: transparent;
						padding: 0;
						border-radius: 7px;
						border: solid;
						width: 40%;
						height: 40%;
					}

					.action_button.false {
						background-color: #00c200;
						color: #ffffff;
					}

					.action_button.true {
						color: #ffffff;
						background-color: #d6433e;
					}

					.action_button:disabled {
						opacity: 0.5;
					}
				}
			}
		}

		.using_profile {
			border-radius: 16px;
			display: flex;
			height: 50%;
			margin: 20px;
			padding: 10px;
			min-width: 360px;
			width: 40%;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			background-color: #00000088;
			border: 1px solid #ffffff;
			color: #fbfbfd;

			.user_using_prof_pic {
				border: 1px solid #000000;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 150px;
				margin-bottom: 15px;
				overflow: hidden;
				border-radius: 100%;
			}

			.warning {
				color: #ff9100;
			}
		}
	}

	.image_container {
		display: flex;
		align-items: center;
		height: 55vh;
		justify-content: center;

		.back_button {
			position: absolute;
			left: 20px;
			background-color: transparent;
			border: none;
			top: 50px;
		}

		.device_image {
			border-radius: 16px;
			height: auto;
			width: 80%;
			max-width: 450px;
		}
	}

	.history {
		width: 100%;
		margin-top: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		.history_table {
			width: 100%;
			border-collapse: collapse;
			word-break: break-all;

			table,
			td {
				border: 1px solid #000000;
				padding: 5px;
				font-size: 12px;
			}
		}
	}
}

.statistics-other {
	margin-top: 50px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	.statistics {
		display: flex;
		flex-direction: column;
		align-items: center;

		.tables {
			width: auto;
			display: flex;
			flex-direction: row;
			align-items: center;
			flex-wrap: wrap;
			justify-content: center;
		}
	}

	.other-anuncio {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		width: 400px;
	}
}

.admin-panel {
	min-height: 100vh;
	padding-top: 44px;
	.title {
		width: 100%;
		text-align: center;
	}
}
