@import url("https://fonts.cdnfonts.com/css/sf-pro-display");

* {
	margin: 0px;
	font-family: "SF Pro Display", sans-serif;
}
body {
	background-color: rgba(255, 255, 255, 0.94);
	color: #000000;
	scroll-behavior: smooth;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
@keyframes parpadeo {
	50% {
		opacity: 0.7;
		scale: 1.01;
	}
	100% {
		opacity: 1;
		scale: 1;
	}
}
.Toastify{
	z-index: 99999999999;
	position: absolute;
}
.aztech-letters {
	background: linear-gradient(0deg, transparent, #003399 40%);
	.letters {
		min-height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: nowrap;
		flex-direction: column;
		transition: all;
		animation: parpadeo 1s infinite;
		.blind-containers {
			display: flex;
			width: 80%;
			height: auto;
			justify-content: center;
			align-items: center;
			flex-wrap: nowrap;
			flex-direction: column;
			.blind-1 {
				width: 100%;
				position: relative;
				background-color: #ffffff00;
			}
			.blind-2 {
				width: 80%;

				position: absolute;
				filter: blur(3px);
			}
		}
		svg {
			width: 100%;
			height: auto;
			fill: #ffffff !important;
		}
	}
}

@media (max-width: 600px) {
	.section-left,
	.section-right {
		width: 100% !important;
	}
}

.frame {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 40vh;
	.reverse {
		flex-wrap: wrap-reverse !important;
	}
	.divided {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		flex-wrap: wrap;
		.section-left {
			display: flex;
			min-width: 300px;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			flex-wrap: nowrap;
			padding: 40px;
			padding-bottom: 0;
			width: 50%;
		}
		.section-right {
			width: 50%;
			display: flex;
			justify-content: center;
			.proyectossvg {
				padding: 50px;
				width: 90%;
				height: auto;
			}
			.proyectossvgmenos {
				padding: 50px;
				width: 60%;
				height: auto;
			}
		}
		p {
			text-align: justify;
		}
		h2 {
			text-align: center;
		}
	}
}

@media (max-width: 600px) {
	.sponsors-bar {
		grid-template-columns: repeat(6, 1fr);
		.sponsor {
			svg {
				width: 80%;
			}
		}
	}
}
@media (min-width: 600px) {
	.sponsors-bar {
		grid-template-columns: repeat(3, 1fr);
		.sponsor {
			svg {
				width: 80%;
			}
		}
	}
}
@media (max-width: 600px) {
	.sponsors-bar {
		grid-template-columns: repeat(2, 1fr);
		.sponsor {
			svg {
				width: 80%;
			}
		}
	}
}
.sponsors-title {
	text-align: center;
}
.sponsors-bar {
	display: grid;
	gap: 20px;
	.sponsor {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px;
	}
	svg {
		fill: #000000 !important;
		max-height: 100px;
		max-width: 200px;
	}
}

//Login
.last_steps {
	transition: 0.5s ease-in-out;
	color: #000000;
	background-color: #fbfbfd;
	border: none;
	input,
	select {
		color: #000000;
		background-color: transparent;
		border: 1px solid #d6d6d6;
	}

	button {
		animation: fade-in 0.5s ease-in-out;

		background-color: #ffffff;
		border: solid 1px #d6d6d6;
		color: #000000;
	}
}
.error404 {
	h1,
	a {
		color: #ffffff;
	}
}
//login

.signup-container {
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
	flex-wrap: wrap;
	flex-direction: column;
	min-height: 100vh;
	height: 100%;
	background: rgba(255, 255, 255, 0.94);
	padding-top: 35px;
}

@media (max-width: 780px) {
	.last_steps {
		width: 100%;
		min-height: 100vh;
		border-radius: 0;
		border-width: 0 !important;

		.hr {
			width: 260px;
			display: initial;
			margin-top: 30px;
			height: 2px;
			border: none;
			background-color: #000000;
			margin: 30px 70px 0px 70px;
		}
	}
}

@media (min-width: 780px) {
	.last_steps {
		width: auto;
		border-radius: 40px;
		margin-top: 10%;
		margin-bottom: 10%;
		height: auto;
		.hr {
			display: none;
		}
	}
}

.last_steps {
	display: flex;
	padding: 0px 20px 30px 20px;
	flex-direction: column;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-around;
	align-content: center;
	box-shadow: 1px 11px 30px -3px rgba(0, 0, 0, 0.58);
	-webkit-box-shadow: 1px 11px 30px -3px rgba(0, 0, 0, 0.58);
	-moz-box-shadow: 1px 11px 30px -3px rgba(0, 0, 0, 0.58);
	.last_steps_text {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-wrap: nowrap;
		justify-content: center;
	}
	.terminos {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		input {
			width: 20px;
			margin: 15px;
			height: 1.6em;
		}
	}
	a {
		text-decoration: underline;
		color: #0066cc;
	}
}

.formulario_auth {
	justify-content: center;
	align-content: center;
	flex-wrap: wrap;
	flex-direction: column;
	display: flex;
	align-items: center;
	margin: 0 50px;
	input,
	select {
		border-radius: 5px;
		height: 2.6em;
		min-width: 150px;
		width: 270px;
	}

	select:invalid {
		color: gray;
	}

	input,
	select,
	button {
		margin-top: 15px;
	}

	button {
		height: 35px;
		width: 270px;
		border-radius: 5px;
	}

	button:enabled {
		cursor: pointer;
	}
	button:disabled {
		opacity: 0.5;
	}
	.error {
		border-radius: 5px;
		min-height: 35px;
		margin-top: 15px;
		min-width: 150px;
		width: 270px;
		background-color: #c74a4aa3;
		border: solid 2px #be0000e0;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

@media (max-width: 750px) {
	.dropdown {
		display: flex !important;
		position: absolute;
		top: 44px;
		left: 0;
		z-index: 9991;
		background-color: #171717e7;
		width: 100%;
	}

	.title {
		text-align: center;
		margin-top: 30px;
	}
	.cards {
		justify-content: center !important;
	}
	.container_account {
		flex-wrap: wrap !important;
	}
	.left-column {
		color: #ffffff !important;
	}
	.left-column.false {
		transition: all 0.5s !important;
		height: 0px;

		position: absolute;
		width: 100% !important;
		z-index: -10;
		* {
			visibility: hidden;
		}
	}
	.left-column.true {
		transition: all 0.5s !important;
		position: fixed;
		z-index: 999;
		padding: 50px;
		height: auto;
		display: flex !important;
		align-items: center !important;
		background-color: #171717e7;
		width: 100% !important;
		top: 84px;
		left: 0;
		+ .second {
			opacity: 0.2;
		}
	}
	.prof_pic {
		display: flex;
		justify-content: center;
		flex-direction: column;
		flex-wrap: nowrap;
		align-items: center;
		.img_profile {
			border-color: #ffffff !important;
		}
	}
	.tab-list {
		display: flex;
		justify-content: center;
		flex-direction: column;
		flex-wrap: nowrap;
		align-items: center;
	}
}
@media (max-width: 500px) {
	.account {
		justify-content: center !important;
		.container_account {
			width: 100% !important;
		}
		.title {
			margin-top: 30px;
			text-align: center;
		}
		.cards {
			justify-content: center !important;
		}
	}
}

.account {
	min-height: 100vh;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	.container_account {
		margin: 80px 0;
		width: 70%;
		display: flex;
		justify-content: center;
		flex-wrap: nowrap;
		padding-left: 10px;
		.dropdown {
			display: none;
			height: 40px;
			align-items: center;
			justify-content: flex-end;
			button {
				background-color: transparent;
				color: #ffffff;
				border: none;
				padding: 15px 20px 10px 0;
				height: auto;
				font-size: 20px;
			}
		}

		.left-column {
			width: 250px;
			display: flex;
			flex-wrap: nowrap;
			flex-direction: column;
			min-width: 200px;
			justify-content: flex-start;
			.prof_pic {
				margin-bottom: 40px;
				overflow: hidden;
				height: auto;
				.img_profile {
					width: 100px;
					height: 100px;
					margin: 15px 0;
					border-radius: 100px;
					border: #000000 solid 1px;
				}
			}
			.active-tab p {
				font-weight: 700;
				color: #0066cc;
			}
			.tab-list {
				transition: all 0.2s;
				padding: 0;
				list-style: none;
				li {
					font-size: 17px;
					transition: all 0.2s;
					padding: 5px 0;
				}
				li:hover {
					scale: 1.05;
					cursor: pointer;
				}
			}
		}
		.second {
			max-width: 680px;
			height: auto;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: center;
			.right-column {
				.title {
					padding: 0 0 50px 0px;
					width: 100%;
				}
				.cards {
					width: 100%;
					width: auto;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
				}
				background-color: rgb(255, 255, 255);
				width: auto;
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;
				align-items: center;
				align-content: flex-start;
				height: 100%;
			}
		}
	}
}
.error404 {
	min-height: 100vh;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	align-content: center;
	flex-direction: column;
	background-color: #003399;
	h1 {
		font-size: 50px;
	}
	a {
		text-decoration: none;
		border-radius: 1px solid #ffffff;
		font-size: 30px;
		border-radius: 7px;
	}
}

.working{
	display: flex;
	justify-content: center;
	align-items: center;

	img{
		width: 100%;
		height: auto;
		
	}
	.text{
		padding: 100px;
		color: #ffffff;
		text-align: center;
		width: 50%;
		background-color: #000000c2;
		border-radius: 11px;
		position: absolute;
	}
}
@keyframes show{
	from{
		opacity: 0;
		scale: 25%;

	}
	to{
		opacity: 1;
		scale: 100%;
	}

}


.divided, .divided-reverse{
	view-timeline-name: --image;
	view-timeline-axis: block;
	animation-timeline: --image;
	animation-range: entry 25% cover 50%;
	animation-name: show;
	animation-fill-mode: both;
}

.divided p, .divided-reverse p{ 
	padding: 0 100px;
}