@media (max-width: 730px) {
	.modal {
		height: 100% !important;
		width: 100% !important;
		justify-content: space-between;
	}
	.modal-footer {
		justify-content: center !important;
		button {
			margin: 10px;
		}
	}

	.centered-modal {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
}
.centered-modal {
	min-height: 100vh;
	width: 100%;
	height: 100%;
	z-index: 100000;
	padding-top: 90px;
	padding-bottom: 90px;
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(232, 232, 237, 0.9);
	display: flex;
	align-items: flex-start;
	justify-content: center;
	transition: all 0.5s;
	.modal {
		width: 690px;
		height: auto;
		display: flex;
		flex-direction: column;
		border-radius: 11px;
		filter: drop-shadow(0 11px 34px rgba(0, 0, 0, 0.2));
		background-color: #ffffff;
		.close-button-container {
			width: 100%;
			display: flex;
			align-items: flex-start;
			button {
				transition: all 0.2s;
				background-color: transparent;
				border: none;
				font-size: 25px;
			}
			button:hover {
				scale: 1.2;
			}
		}
		.modal-header {
			margin-top: 22px;
			padding: 0 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			.modal-title {
				font-weight: 600;
				margin: 15px 10px 0;
				font-size: 24px;
			}
			.modal-image {
				width: 54px;
				color: #0066cc;
				height: 54px;
				font-size: 54px;
			}
			.modal-subtitle {
				margin-top: 13px;
				font-size: 17px;
			}
			.modal-description {
				color: #424245;
				font-size: 14px;
				font-weight: 400;
				margin: 20px 0 0;
			}
		}
		.modal-content {
			padding: 0 80px 0px;
			display: flex;
			flex-direction: column;
			.modal-body {
				width: 100%;
				margin-top: 45px;
				.modal-form {
					margin-bottom: 60px;
					width: 100%;
					height: 100%;
					.form-title {
						margin-bottom: 8px;
					}
					input, select {
						transition: all 0.2s;
						max-width: 320px;
						width: 100%;
						border-color: #d2d2d7;
						border-radius: 12px;
						border-width: 1px;
						padding: 0px 17px 0;
						height: 3.2em;
						font-size: 17px;
						border-style: solid;
						margin-bottom: 14px;
					}
					input:hover {
						scale: 1.05;
						border-color: #949494;
					}
				}
			}
		}
		.modal-footer {
			padding: 15px 20px;
			display: flex;
			flex-wrap: wrap;
			border-top: 0.5px solid #424245;
			justify-content: space-between;
			button {
				width: 180px;
				padding: 8px 16px;
				font-size: 17px;
				border-radius: 8px;
				border: none;
			}
			.send {
				background-color: #0066cc;
				color: #ffffff;
			}
			.cancel {
				background-color: #e8e8e8;
				color: #000000;
			}
			button:hover {
				opacity: 0.8;
			}
		}
	}
}

a {
	text-decoration: none;
	color: #000000;
}

.mini-card {
	transition: all 0.2s;
	background-image: linear-gradient(120deg, #fff 45%, #e7e7e7);
	filter: drop-shadow(0 10px 10px rgba(76, 76, 109, 0.07059));
	margin: 0 10px 20px;
	border-radius: 11px;
	border: 1px solid hsla(240, 5%, 80%, 0.65);
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	flex-direction: row;
	justify-content: center;
	padding: 17px;
	.icon-container {
		width: 43px;
		height: 90px;
		.icon {
			svg {
				color: #0066cc;
			}
			width: 30px;
			padding: 10px;
			font-size: 30px;
			img {
				max-width: 30px;
			}
		}
	}
	.info {
		h3 {
			font-size: 19px;
			font-weight: 600;
			color: #000000;
			padding: 5px 0;
			text-decoration: none;
		}
		p {
			color: #6e6e73;
			font-size: 14px;
			padding-top: 3px;
		}
		width: 238px;
		height: 90px;
	}
}
.mini-card:hover {
	cursor: pointer;
	scale: 1.05;
}
