body {
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
	position: static;
}
body::-webkit-scrollbar {
	display: none; /* Safari and Chrome */
}
.Navbar {
	transition: all 0.5s;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	padding: 9px 100px;
	position: fixed;
	width: 100%;
	height: 44px;
	left: 0px;
	top: 0px;
	z-index: 100000;
	background: rgba(22, 22, 23, 0.63) !important;
	backdrop-filter: blur(10px);
	li a {
		text-decoration: none;
		color: #ffffff;
		padding: 4px 9px 4px 9px;
		font-size: 12px;
	}
	li a:hover{
		color: #c5c5c5;
	}
}

.Switch {
	color: #ffffff;
	background-color: transparent;
	font-size: 12px;
	padding: 2px 9px 2px 9px;
	border-radius: 7px;
	border-color: transparent;
	option {
		color: #000000;
		background-color: #ffffff;
	}
	
}
@media (max-width: 1000px) {
	.Navbar .navbar-list a {
		display: none;
	}
	.Navbar .dropdown_icon {
		display: initial;
	}
	
}
@media (min-width: 1000px) {
	.Navbar .dropdown_icon {
		display: none;
	}
}
.logonavbar {
	width: auto;

	height: 25px;
}
.Switch:focus {
	outline: none;
	box-shadow: none;
	border-color: transparent;
}

.ends {
	display: flex;
	.bar {
		font-size: 20px;
		margin-left: 15px;
		color: #ffffff;
	}
}

.navbar-list {
	list-style: none;
	display: flex;
	width: 100%;
	// justify-content: space-between;
	justify-content: space-evenly;
}
.dropdown_icon {
	padding: 0px;
	height: 25px;
	width: 30px;
	border: none;
	background-color: transparent;
	color: #ffffff;
}
.Navbar.true {
	transition: all 0.5s;
	padding: 9px 20px !important;
	background: rgb(0, 0, 0) !important;
	color: #ffffff;
	left: 0;
	position: fixed;
	width: 100%;
	min-height: 100vh;
	a {
		display: initial;
	}
	.navbar-list {
		display: list-item;
		margin-top: 44px;
		padding-left: 0;
		width: 100%;
		position: absolute;
		li {
			width: 100%;
			position: relative;
			padding: 20px 0;
			a {
				font-size: 20px !important;
			}
		}
	}
}

/////////////////////////////

.loading {
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	background-color: #ffffff;
}
.footer-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.footer-1 {
	filter: blur(4px);
	z-index: -10;
	position: absolute;
}
.footer {
	background: rgba(22, 22, 23, 0.63) !important;
	color: #ffffff !important;
	display: flex;
	height: auto;
	width: 100%;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	align-content: space-around;
	.credits {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 100%;
		align-content: space-around;
		flex-wrap: wrap;
		~ hr {
			width: 50%;
			border: solid 1px;
		}
	}
	.credit-logos {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
		width: 100%;
		align-content: center;
		flex-wrap: wrap;
		margin-top: 20px;
		margin-bottom: 20px;
		img {
			max-height: 10vh;
		}
	}
	.firstlogos {
		margin-top: 20px;
		margin-bottom: 20px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-evenly;
		align-items: center;
		width: 100%;
		align-content: center;
		img {
			height: 7vh;
			width: auto;
		}
	}
	
}

.footer_logo{
		width: 10px;
		display: flex;
		
}

.footer_followus{
	li{
	list-style: none;}
	.footer_followus_point{
		display: flex;
	}
}
