
:root {
	--Aro-AMD: rgb(255, 139, 7);
	--Aro-Speak: rgb(255, 7, 7);
	--Aro-failAMD: rgb(190, 136, 73);
	--Aro-failSpeak: rgb(202, 68, 68);
  }
table{
	border-collapse: collapse;
}

.scouting-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	
	input[type="number"],
	 input[type="text"] 
	#Match, #Team{
		margin: 5px;
		width: 300px;
		height: 80px;
		font-size: 40px;
		background-color: #ffffff;
		box-shadow: 0px 0px 3px #6b6b6b;
		border-color: transparent;
		text-align: center;
		
	}
	.table-gradas{
		input[type="number"],
		input[type="text"]{
		margin: 5px;
		width: 100px;
		height: 80px;
		font-size: 40px;
		background-color: #ffffff;
		box-shadow: 0px 0px 3px #6b6b6b;
		border-radius: 30px;
		border-color: transparent;
		text-align: center;}
	}

	input[type="number"]::-webkit-inner-spin-button {
		width: 50px;
		height: 80px;
	}

	input {
		width: 30px;
		height: 30px;
	}
}

.react-rater {
	font-size: 35px;

	.react-rater-star.is-active {
		color: #f7b900;
	}
}

.blue {
	background-color: rgb(0, 38, 255);
}

.red {
	background-color: rgb(189, 0, 0);
}

.blue.true {
	background-color: #1bda01 !important;
}

.red.true {
	background-color: #1bda01 !important;
}

.match-table {
	background-color: #656565;
	color: #ffffff;
	text-align: center;

	td {
		padding: 5px;
	}
}

.alliance-tables {
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: row;
	flex-wrap: wrap;
}

.alliance-points {
	width: 40%;
	text-align: center;
	max-width: 400px;
	color: #ffffff;
	min-width: 400px;
	margin: 20px;
	td {
		border-color: #ffffff;
		padding: 5px;
		width: 40px;
		height: 40px;
	}
}

.individual-alliance-points {
	text-align: center;
	max-width: 550px;
	color: #ffffff;
	.blue {
		background-color: #0110da76 !important;
	}

	.red {
		background-color: #da010176 !important;
	}
	td {
		border-color: #ffffff;
		padding: 5px;
		width: 40px;
		height: 40px;
	}
}
.scouting-menu {
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	
	button {
		border: none;
		border-radius: 17px;
		background-color: transparent;

		margin-top: 50px;
		
		font-size: 20px;
		a {
			color: #525252;
			font-weight:200;
			
		}
		// :hover{
		// 	color:#174b91;
		// 	text-shadow: 0 0 2px #2970ce;
		// 	font-weight: bold;
		// 	padding: 0;
		// }
		
	}
}

.q60 {
	background-color: #ffdd00;
}
.q70 {
	background-color: #33ff00;
}
.neutro {
	background-color: #656565;
}

.alliance-points.blue {
	background-color: #0110da76 !important;
}

.alliance-points.red {
	background-color: #da010176 !important;
}

.team-pits {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-around;
	flex-wrap: wrap;
}

.scouting-layout {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	min-height: 100vh;

	.titles {
		display: flex;
		width: 100%;
		padding-left: 30px;
		justify-content: flex-start;
		flex-direction: column;
	}
}


.scouting-statistics {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	// background-color: #002265;
}

.containercheckbox {
	width: 300px;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	

}
.containercheckbox1{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: left;
	text-align: center;
	.containercheckbox1_position{
		display: flex;
		flex-direction: row;
		align-items: center;
		input{
			margin: 5px;
		}
	}
	
}

textarea{
	color: #525252;
	box-shadow: 0px 0px 3px #6b6b6b;
	resize: none;
	background-color: #ffffff;
	border-color: transparent;
	border-radius: 5px;
	
}

textarea::placeholder{
color: #828282;


}


.draw-component {
	button {
		width: auto;
		padding: 10px;
		height: 40px;
	}
}

.cone {
	fill: #f7b900 !important;
	width: 20px;
	height: 20px;
}

.cube {
	fill: #a100f7 !important;
	width: 20px;
	height: 20px;
}

.scouting {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.table{
td {
	width: 300px;
	border-collapse: collapse;
	margin: 0;
	padding: 0;
	border: 1px solid #ffffff;
	text-align: center;

	td {
		height: 50px;

		input {
			width: 100px !important;
			text-align: center;
		}
	}
	input{
		background-color: #ffffff;
		box-shadow: 0px 0px 3px #6b6b6b;
		border-color: transparent;
		border-radius: 30px;
		justify-content: center;
	}
	:focus{
		color: #174b91;
	}
	

}}
.statistics{
	td{
		border: 1px solid #c5c5c5 !important;
	}
	
}

.Tablla{
	td {
		width: 300px;
		border-collapse: collapse;
		margin: 0;
		padding: 0;
		border: 1px solid #353535;
		text-align: center;
}
}



.regional-buttons {
	display: flex;
	justify-content: center;
	flex-direction: row;
	

	button {
		padding: 10px;
		margin: 5px;
		font-size: 15px;
		border-color: transparent;
		background-color: #bdbdbd;
		box-shadow: 0 1px 2px #6b6b6b ;
		
	}
	button:hover{
		background-color: #828282
	}
	button:focus{
		color: #ffffff;
		background-color: #174b91;
		box-shadow: 0 1px 2px #6b6b6b ;
	}
}


.submit-button {
	background-color: #bdbdbd;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 300px;
	height: 80px;
	font-size: 40px;
	align-items: center;
	border-color: transparent;
	box-shadow: 0 3px 5px #6b6b6b ;
	
}
.submit-button:hover{
	background-color: #828282;
}
.submit-button:focus{
	transition: 100ms;
	color: #ffffff;
	background-color: #174b91;
}


.foto{
	
	font-size: 34px;
}

#input_failsSpeakerTELEOP{
	height: 80px;
	border-radius: 30px;
}

.aroAMD{
	color: var(--Aro-AMD);
}
.aroSpeak{
	color: var(--Aro-Speak)
}
.aroFallosSpeak{
	color: var(--Aro-failSpeak)
}
.aroFallosAMD{
	color: var(--Aro-failAMD)
}


/* Menu Hover Effects Style 1 */
// :root {
// 	--BottomOffset: 20px;
// 	--LineHeight: 5px;
// 	--LineColor: #29EECC;
//   }

// .scouting-menu a {
// 	position: relative;
// 	z-index: 1;
// }
// .scouting-menu  a:before {
//     position: absolute;
// 	color: #174b91;
//     content: '';
//     display: block;
//     z-index: -1;
//     background: var(--LineColor);
//     width: 0;
//     height: var(--LineHeight);
//     bottom: var(--BottomOffset);
// 	border-radius:var(--LineHeight);
//     left: 50%;
//     transition: 0.4s cubic-bezier(0.25, 0.1, 0, 2.02);
// }

// .scouting-menu  a:hover:before {
//     width: calc(100% + var(--LineHeight));
//     left: calc(var(--LineHeight) / -2);
// 	color: #174b91;
// }
